import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/header'
import ArticlePreview from '../components/article-preview'
import { StaticImage } from 'gatsby-plugin-image'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout author={author.node}>
        <Helmet
          title={siteTitle}
          meta={[
            { name: 'description', content: author.node.shortBio.shortBio },
            {
              name: 'keywords',
              content:
                'blog, technology, cloud, code, web development, architecture',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header
          author={author.node}
          image={author.node.image.file.url}
          home={true}
        >
          <div className="site-heading">
            <StaticImage src="../assets/logo.png" alt="Boxes + Arrows" />
            <span className="subheading">{author.node.shortBio.shortBio}</span>
          </div>
        </Header>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              {posts.map(({ node }, key) => {
                return <ArticlePreview key={key} article={node} />
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          image {
            file {
              url
            }
          }
          linkedIn
          twitter
          github
        }
      }
    }
  }
`
